.container {
  width: 100%;
  max-width: 1146px;
  margin: auto;
  padding: 60px 30px;
  box-sizing: border-box;
}

.vQContainer {
  display: flex;
}

.imageContainer {
  width: 45%;
  box-sizing: border-box;
}

.imageContainer img {
  width: 100%;
}

.descriptionContainer {
  width: 55%;
  padding: 0 2.5rem;
  box-sizing: border-box;
}

.descriptionContainer h2 {
  margin: 0;

  font-family: 'Jost';
  font-weight: 700;
  font-size: 36px;
  letter-spacing: -0.02em;

  color: #373E42;
}

.descriptionContainer ul {
  padding-left: 1.4rem;
}

.descriptionContainer ul li {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.02em;
  color: #000000;
}

.descriptionContainer ul li {
  list-style-type: none;
  position: relative;    /* It's needed for setting position to absolute in the next rule. */
}

.descriptionContainer ul li::before {
  content: '•';
  position: absolute;
  left: -0.8em;          /* Adjust this value so that it appears where you want. */
  font-size: 1.1em;      /* Adjust this value so that it appears what size you want. */
}

/* SWITCH */
.switchContainer {
  background-color: #373E42;
  border-radius: 32px;
  padding: 5px;
  width: 100%;
  max-width: 174px;

  display: flex;
  flex-direction: row;
  justify-content: space-around;

  position: relative;
  margin: 0.75rem 0;
}

.switchContainer::after {
  content: '';
  position: absolute;
  height: calc(100% - 10px);
  width: calc(50% - 5px);
  /* left: calc(0px + 5px); */
  left: calc(50% + 0px);
  background-color: #FFFFFF;
  border-radius: 32px;
  transition: 0.2s;
}

.switchContainer .annual {
  background: transparent;
  border: none;
  border-radius: 32px;
  width: 50%;
  text-align: center;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;

  padding: 6px 0;
  z-index: 2;
  cursor: pointer;
  color: #FFFFFF;
  transition: 0.2s;
}

.switchContainer .monthly {
  background: transparent;
  border: none;
  border-radius: 32px;
  width: 50%;
  text-align: center;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;

  padding: 6px 0;
  z-index: 2;
  cursor: pointer;
  color: #373E42;
  transition: 0.2s;
}

.switchContainer.switch::after {
  left: calc(0px + 5px);
}

.switchContainer.switch .annual {
  color: #373E42;
}

.switchContainer.switch .monthly {
  color: #FFFFFF;
}

/* PRICE */
.price {
  margin: 1.75rem 1rem;
  /* margin: 1rem; */
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 36px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
}

.price span {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 20px;
  letter-spacing: -0.02em;
  color: #000000;
  padding: 0 1rem;
}

/* BUTTON */
.buttonContainer button {
  align-items: center;
  padding: 14px;
  gap: 8px;

  background: #1DAD6C;
  border-radius: 32px;
  border: none;

  font-family: 'Roboto';
  font-weight: 700;
  font-size: 16px;
  color: #FFFFFF;

  display: flex;
  justify-content: space-around;
  cursor: pointer;
}

.buttonContainer button svg {
  padding: 4px;
}

.buttonContainer button[disabled], .buttonContainer button[disabled]:hover {
  cursor: not-allowed;
  background-color: #99A2A8;
}

.subscriptionsContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.subscriptionsContainer a {
  margin-left: 1rem;
  font-family: 'Roboto';
  color: #373E42;
  font-weight: 600;
  font-size: 16px;
  text-decoration: underline;
  transition: 0.2s;
}

@media only screen and (max-width: 769px) {
  .mainContainer {
    padding-top: 84px;
  }

  .container {
    padding: 0 2rem;
  }

  .vQContainer {
    flex-direction: column;
  }

  .vQContainer > div {
    width: 100%;
    padding: 2.5rem 0;
  }

  .priceContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .priceContainer .price {
    display: flex;
    flex-direction: column;
  }

  .priceContainer .price span {
    padding-top: 1.5rem;
  }

  .subscriptionsContainer {
    justify-content: center;
  }

  .subscriptionsContainer a {
    margin-left: 0;
  }
}
